<template>
  <div class="activity-form">
    <br>
    <h1>上传参赛作品</h1>
    <div class="flex input-row">
      <input v-model="signInfo.name" type="text" placeholder="请输入姓名">
      <input v-model="signInfo.mobile" type="number" placeholder="请输入电话">
    </div>
    <div class="flex input-row">
      <div class="input">
        <RadioGroup class="flex" v-model="signInfo.type" v-if="id==1150580668737118210">
          <Radio label="1">照片征集</Radio>
          <Radio label="2">文稿征集</Radio>
        </RadioGroup>
      </div>
      <div class="input2">
        <Col span="16">
          <input v-model="signInfo.msgCode" type="number" placeholder="请输入验证码">
        </Col>
        <Col span="8" class="flex" style="height: 55px">
          <Button @click="getCode" :disabled="smsDisable" :loading="sendingCode" type="primary" class="code">{{smsDisable? smsTime:sendingCode? '':'获取验证码'}}</Button>
        </Col>
      </div>  
    </div>
    <div>
      <Upload action="" :show-upload-list="false" :disabled="uploading" slot="append" :before-upload="onRead">
        <Button type="primary" class="upload" :loading="uploading" icon="md-cloud-upload">
          {{uploading? '上传中..':'上传附件'}}
        </Button>
        <span class="file-name">{{fileName}}</span>
      </Upload>
    </div>
    <div class="flex input-row">
      <textarea v-model="signInfo.memo" rows="3" placeholder="留言内容"></textarea>
    </div>
    <div class="btn tc" @click="submit()">提交</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      uploading: false,
      fileName: '',
      showDialog: false,
      submiting: false,
      sendingCode: false,
      smsDisable: false,
      smsTimer: null,
      smsTime: 60,
      signInfo: {
        activityId: this.$route.params.id,
        name: '',
        mobile: '',
        type: '2',
        unitName: '',
        enclosure: '',
        memo: '',
        msgCode: '', // 验证码
      },
      ruleValidate: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        unitName: [{ required: true, message: '请输入学校/单位名称', trigger: 'blur' }],
      },
    }
  },
  methods: {
    getCode() {
      if (!this.signInfo.mobile) {
        this.$Message.warning('请先输入手机号')
        return
      }
      if (!(/^((1[3-9][0-9])+\d{8})$/).test(this.signInfo.mobile)) {
        this.$Message.warning('请输入正确的手机号')
        return
      }
      this.sendingCode = true
      this.$http.get('/sys/sms/sendCheck',{
        params: {
          mobile: this.signInfo.mobile
        }
      }).then(res => {
        this.sendingCode = false
        if (res.code === 0) {
          this.signInfo.msgHash = res.data.msgHash
          this.signInfo.msgTime = res.data.msgTime
          this.$Message.success('短信已发送')
          this.smsDisable = true
          this.smsTimer = setInterval(() => {
            this.smsTime--
            if (this.smsTime === 0) {
              this.smsDisable = false
              clearInterval(this.smsTimer)
              this.smsTimer = null
              this.smsTime = 60
            }
          }, 1000);
        } else {
          this.$Message.warning(res.msg)
        }
      }).catch(err => {
        this.sendingCode = false
        this.$Message.warning(err.message)
      })
    },
    reSet() {
      this.fileName = ''
      this.signInfo = {
        activityId: this.$route.params.id,
        name: '',
        mobile: '',
        unitName: '',
        enclosure: '',
        memo: ''
      }
    },
    onRead(e) {
      this.uploading = true
      this.$store.dispatch('uploadFile',{file: e, path: '/collection/'}).then(url=> {
        this.uploading = false
        this.fileName = e.name
        this.signInfo.enclosure = url
      }).catch(err => {
        this.uploading = false
      })
    },
    submit() {
      if (!this.signInfo.name) {
        this.submiting = false
        this.$Message.warning('请输入姓名')
        return
      }
      if (!this.signInfo.mobile) {
        this.submiting = false
        this.$Message.warning('请输入电话号码')
        return
      }
      if (!this.signInfo.msgCode) {
        this.submiting = false
        this.$Message.warning('请输入验证码')
        return
      }
      if (!this.signInfo.enclosure) {
        this.$Message.warning('请上传附件')
        this.submiting = false
        return
      }
      this.submiting = true
      this.$http.post('/meeting/activitysign/',
        this.signInfo
      ).then(res => {
        this.submiting = false
        if (res.code === 0) {
          this.showDialog = false
          this.reSet()
          this.$Message.success('提交成功')
        } else {
          this.$Message.warning(res.msg)
        }
      }).catch(err => {
        this.submiting = false
        this.$Message.error(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.activity-form {
  margin: 20px auto;
  width: 1060px;
  height: 500px;
  border: 1px solid rgba(74, 145, 226, 0.5);
  border-radius: 3px;
  background: #EEF6FF;
  .btn {
    font-size: 16px;
    color: #FFF;
    width: 240px;
    height: 55px;
    background: #4A90E2;
    border-radius: 4px;
    line-height: 55px;
    margin: 0 auto;
    user-select: none;
    cursor: pointer;
    margin-top: 10px;
  }
  h1 {
    line-height: 80px;
    color: #0769CC;
    text-align: center;
  }
  .input-row {
    justify-content: space-around;
    padding: 10px 0;
    input, textarea, .input {
      padding: 15px;
      font-size: 15px;
      color: #333;
      width: 450px;
      height: 55px;
      background: #FFF;
      border-radius: 4px;
      .flex {
        justify-content: space-around;
      }
    }
    .input2 {
      width: 450px;
      input {
        width: 300px;
      }
      .flex {
        height: 100%;
        justify-content: flex-end;
      }
    }
    textarea {
      height: auto;
      width: calc(100% - 80px);
    }
  }
  .code {
    height: 49px;
  }
  .ivu-radio-group {
    padding-left: 25px;
  }
  .ivu-radio-wrapper {
    color: #333;
    font-size: 18px;
    margin-right: 25px;
  }
  .ivu-upload {
    margin-left: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .upload {
    background: #B4D9FF;
    color: #0769CC;
    border: 1px solid #0769CC;
  }
  .file-name {
    color: #333;
    margin-left: 10px;
  }
}
</style>
