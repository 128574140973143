<template>
  <div class="activity-form">
    <br>
    <h1>报名活动</h1>
    <div class="flex input-row">
      <input v-model="signInfo.name" type="text" placeholder="请输入姓名">
      <input v-model="signInfo.mobile" type="number" placeholder="请输入电话">
    </div>
    <div class="flex input-row">
      <input v-model="signInfo.unitName" type="text" placeholder="请输入学校/单位">
      <div class="input2">
        <Col span="16">
          <input v-model="signInfo.msgCode" type="number" placeholder="请输入验证码">
        </Col>
        <Col span="8" class="flex" style="height: 55px">
          <Button @click="getCode" :disabled="smsDisable" :loading="sendingCode" type="primary" class="code">{{smsDisable? smsTime:sendingCode? '':'获取验证码'}}</Button>
        </Col>
      </div>
    </div>
    <div class="flex input-row">
      <input v-model="signInfo.projectName" type="text" placeholder="请输入表述语">
      <div class="input2"></div>
    </div>
    <div class="flex input-row">
      <textarea v-model="signInfo.memo" rows="2" placeholder="表述语释义"></textarea>
    </div>
    <br>
    <div class="btn tc" @click="submit()">{{submiting?'提交中..':'提交'}}</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      uploading: false,
      showDialog: false,
      submiting: false,
      sendingCode: false,
      smsDisable: false,
      smsTimer: null,
      smsTime: 60,
      signInfo: {
        activityId: this.$route.params.id,
        name: '',
        mobile: '',
        projectName: '',
        unitName: '',
        memo: '',
        msgCode: '', // 验证码
      },
      ruleValidate: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        unitName: [{ required: true, message: '请输入学校/单位名称', trigger: 'blur' }],
      },
    }
  },
  methods: {
    getCode() {
      if (!this.signInfo.mobile) {
        this.$Message.warning('请先输入手机号')
        return
      }
      if (!(/^((1[3-9][0-9])+\d{8})$/).test(this.signInfo.mobile)) {
        this.$Message.warning('请输入正确的手机号')
        return
      }
      this.sendingCode = true
      this.$http.get('/sys/sms/sendCheck',{
        params: {
          mobile: this.signInfo.mobile
        }
      }).then(res => {
        this.sendingCode = false
        if (res.code === 0) {
          this.signInfo.msgHash = res.data.msgHash
          this.signInfo.msgTime = res.data.msgTime
          this.$Message.success('短信已发送')
          this.smsDisable = true
          this.smsTimer = setInterval(() => {
            this.smsTime--
            if (this.smsTime === 0) {
              this.smsDisable = false
              clearInterval(this.smsTimer)
              this.smsTimer = null
              this.smsTime = 60
            }
          }, 1000);
        } else {
          this.$Message.warning(res.msg)
        }
      }).catch(err => {
        this.sendingCode = false
        this.$Message.warning(err.message)
      })
    },
    reSet() {
      this.signInfo = {
        activityId: this.$route.params.id,
        name: '',
        mobile: '',
        projectName: '',
        unitName: '',
        memo: '',
        msgCode: '', // 验证码
      }
    },
    submit() {
      if (this.submiting == true) {
        return
      }
      if (!this.signInfo.name) {
        this.submiting = false
        this.$Message.warning('请输入姓名')
        return
      }
      if (!this.signInfo.mobile) {
        this.submiting = false
        this.$Message.warning('请输入电话号码')
        return
      }
      if (!this.signInfo.msgCode) {
        this.submiting = false
        this.$Message.warning('请输入验证码')
        return
      }
      if (!this.signInfo.unitName) {
        this.$Message.warning('请输入单位或学校')
        this.submiting = false
        return
      }
      if (!this.signInfo.projectName) {
        this.$Message.warning('请输入表述语')
        this.submiting = false
        return
      }
      if (!this.signInfo.memo) {
        this.$Message.warning('请输入表述语释义')
        this.submiting = false
        return
      }
      this.submiting = true
      this.$http.post('/meeting/activitysign/',
        this.signInfo
      ).then(res => {
        this.submiting = false
        if (res.code === 0) {
          alert('报名成功！')
          this.reSet()
        } else {
          this.$Message.warning(res.msg)
        }
      }).catch(err => {
        this.submiting = false
        this.$Message.error(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.activity-form {
  margin: 20px auto;
  width: 1060px;
  height: 540px;
  border: 1px solid rgba(74, 145, 226, 0.5);
  border-radius: 3px;
  background: #EEF6FF;
  .btn {
    font-size: 16px;
    color: #FFF;
    width: 240px;
    height: 55px;
    background: #4A90E2;
    border-radius: 4px;
    line-height: 55px;
    margin: 0 auto;
    user-select: none;
    cursor: pointer;
    margin-top: 10px;
  }
  h1 {
    line-height: 80px;
    color: #0769CC;
    text-align: center;
  }
  .input-row {
    justify-content: space-around;
    padding: 10px 0;
    input, textarea, .input {
      padding: 15px;
      font-size: 15px;
      color: #333;
      width: 450px;
      height: 55px;
      background: #FFF;
      border-radius: 4px;
      .flex {
        justify-content: space-around;
      }
    }
    .input2 {
      width: 450px;
      input {
        width: 300px;
      }
      .flex {
        height: 100%;
        justify-content: flex-end;
      }
    }
    textarea {
      height: auto;
      width: calc(100% - 80px);
    }
  }
  .code {
    height: 49px;
  }
  .ivu-radio-group {
    padding-left: 25px;
  }
  .ivu-radio-wrapper {
    color: #333;
    font-size: 18px;
    margin-right: 25px;
  }
  .ivu-upload {
    margin-left: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .upload {
    background: #B4D9FF;
    color: #0769CC;
    border: 1px solid #0769CC;
  }
  .file-name {
    color: #333;
    margin-left: 10px;
  }
}
</style>
