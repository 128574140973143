<template>
<div class="activity-bg">
<div class="activity-bg2">
<div class="activity-outer">
  <div class="activity-info center">
    <div :class="'status-wrap'+info.showStatus"></div>
    <BTitle :text="info.title"></BTitle>
    <div class="activity-sub tc">
      <span>发布时间：{{$helper.formatTime(parseInt(info.createDate), 'Y-M-D')}}</span><span>供稿：{{info.author}}</span>
    </div>
    <div class="flex activity-tab" v-if="info.showOpus">
      <div class="tab tc l" :class="showDetail&&'cur'" @click="showDetail=true">活动详情</div>
      <div class="tab tc r" :class="!showDetail&&'cur'" @click="showDetail=false">参赛作品</div>
    </div>
    <!-- 活动详情tab -->
    <div v-if="showDetail">
      <div class="activity-content mce-content-body rich-text" v-html="info.content"></div>
      <component v-if="info.enableSign" :is="'ACT'+id"></component>
    </div>
    <!-- 作品展示tab -->
    <div v-else class="flex act-wrap">
      <div class="item" v-for="(item, index) in actList" :key="index" @click="getActDetail(item)">
        <div class="img"><img :src="item.logoUrl"></div>
        <div class="text">
          <h3 class="tc">{{item.title || '未知'}}</h3>
          <p class="tc">{{item.userName}}</p>
          <!-- <p>{{$helper.formatTime(parseInt(item.createDate), 'Y-M-D')}}</p> -->
        </div>
      </div>
      <Modal v-model="actDetail" class="activity-info-modal" title="作品详情" width="832">
        <div class="prv-wrap flex">
          <img :src="actDetail[actIndex]">
        </div>
        <div class="flex prv-select-wrap">
          <div class="left flex" @click="cur(false)"><Icon type="ios-arrow-back" /></div>
          <div class="item-wrap">
            <div v-for="(url, index) in actDetail" @click="actIndex = index" :key="index" class="item" :class="actIndex == index&&'cur'"><img :src="url"></div>
          </div>
          <div class="right flex" @click="cur(true)"><Icon type="ios-arrow-forward" /></div>
        </div>
        <div slot="footer"><Button type="primary" @click="actDetail=false">确定</Button></div>
      </Modal>
    </div>
  </div>
</div>
</div>
</div>
</template>
<script>
import ACT1149220984356671489 from '@/components/ActivityForm/1149220984356671489'
import ACT1150580668737118210 from '@/components/ActivityForm/1150580668737118210'
import ACT1155658220774199297 from '@/components/ActivityForm/1155658220774199297'
import ACT1155665163757924353 from '@/components/ActivityForm/1155665163757924353'
export default {
  components: {
    ACT1149220984356671489, // 吉祥物征集大赛表单
    ACT1150580668737118210, // 
    ACT1155658220774199297,
    ACT1155665163757924353
  },
  data() {
    return {
      id: this.$route.params.id,
      info: {},
      showDetail: true,
      actList: [], // 作品列表
      actDetail: false, // 某个作品的图片列表
      actIndex: 0,
    }
  },
  created() {
    this.$http.get('/meeting/activity/'+this.id,{
    }).then(res => {
      if (res.code === 0) {
        this.info = res.data
      }
    })
    this.$http.get('/meeting/opus/listByAct',{
      params: { activityId: this.id }
    }).then(res => {
      if (res.code === 0) {
        this.actList = res.data
      }
    })
  },
  methods: {
    getActDetail(item) {
      this.actDetail = JSON.parse(item.bannerUrl)
      this.actIndex = 0
    },
    cur(flag) {
      if (flag) {
        this.actIndex<this.actDetail.length-1&&this.actIndex ++
      } else {
        this.actIndex>0&&this.actIndex --
      }
    },
  }
}
</script>

<style lang="less" scoped>
.activity-bg2 {
  background-image: url('//itie-static.oss-cn-hangzhou.aliyuncs.com/assets/atbg.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 50% center;
}
.activity-bg {
  background: linear-gradient(#FFF, #DEDEDE);
}
.activity-outer {
  background-image: url('../assets/views_bg.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: bottom;
  background-size: 100%;
}
.activity-info {
  position: relative;
  .status-wrap0 {
    left: -80px;
    top: 300px;
    position: absolute;
    width: 54px;
    height: 500px;
    background: url('../assets/buzhou0.png') no-repeat;
    background-size: 54px;
  }
  .status-wrap1 {
    left: -80px;
    top: 300px;
    position: absolute;
    width: 54px;
    height: 500px;
    background: url('../assets/buzhou1.png') no-repeat;
    background-size: 54px;
  }
  .status-wrap2 {
    left: -80px;
    top: 300px;
    position: absolute;
    width: 54px;
    height: 500px;
    background: url('../assets/buzhou2.png') no-repeat;
    background-size: 54px;
  }
  min-height: 100vh;
  padding: 25px 0;
  .activity-sub {
    span {
      color: #333;
      margin: 0 20px;
      font-size: 16px;
      font-size: 500;
      line-height: 40px;
    }
  }
  .activity-tab {
    border-radius: 5px;
    padding: 20px 50px;
    .tab {
      height: 60px;
      color: rgba(255, 255, 255, .7);
      font-size: 18px;
      line-height: 60px;
      flex: 1;
      background: #FFF;
      border: 1px solid #2d8cf0;
      cursor: pointer;
      color: #2d8cf0;
    }
    .r {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    .l {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .cur {
      background: #2d8cf0;
      border: 1px solid #2d8cf0;
      color: #FFF;
      font-weight: 500;
    }
  }
  .act-wrap {
    justify-content: flex-start;
    padding: 20px 40px;
    flex-wrap: wrap;
    .item {
      margin: 10px;
      width: 230px;
      height: 314px;
      border-radius: 5px;
      .img {
        width: 100%;
        height: 230px;
        img {
          width: 100%;
          height: 230px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }
      .text {
        padding: 10px 15px;
        height: 84px;
        border: 1px solid #6F68F7;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        h3 {
          font-size: 16px;
          margin: 0;
          color: #4A8AFF;
          line-height: 35px;
          font-weight: 500;
        }
        p {
          font-size: 14px;
          color: #9CB6E7;
          line-height: 25px;
        }
      }
    }
  }
  .activity-content {
    padding: 20px 10px;
    min-height: 50vh;
  }
}
.prv-wrap {
  user-select: none;
  width: 800px;
  height: 450px;
  img {
    max-height: 450px;
    max-width: 800px;
  }
}
.prv-select-wrap {
  user-select: none;
  padding: 15px;
  .left, .right {
    width: 50px;
    text-align: center;
    font-size: 30px;
    line-height: 63px;
    cursor: pointer;
  }
  .item-wrap {
    height: 70px;
    width: 700px;
    overflow-x: auto;
    white-space: nowrap;
    .item {
      width: 112px;
      height: 63px;
      border-radius: 3px;
      border: 1px solid #333;
      cursor: pointer;
      margin: 0 10px;
      display: inline-block;
      img {
        width: 110px;
        height: 61px;
      }
    }
    .cur {
      border: 1px solid #2B5FFE;
      box-shadow: 0 0 5px #2B5FFE;
    }
  }
}
</style>
<style lang="less">
.activity-content {
  color: #FFF;
  img {
    max-width: 100%;
  }
}
.activity-info-modal {
  .ivu-modal-close {
    .ivu-icon-ios-close {
      color: #FFF;
    }
  }
  .ivu-modal-header {
    background: linear-gradient(270deg,rgba(43,95,254,1) 0%,rgba(68,137,252,1) 100%);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    .ivu-modal-header-inner {
      color: #FFF;
    }
  }
  .ivu-modal-body {
    .tip {
      position: relative;
      width: 100%;
      border: 1px solid #91D5FF;
      border-radius: 4px;
      background: #E6F7FF;
      padding: 10px;
      padding-left: 36px;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 30px;
      b {
        font-weight: bolder;
        line-height: 30px;
        color: rgba(0, 0, 0, 0.85);
      }
      .iinfo {
        position: absolute;
        top: 15px;
        left: 12px;
        color: #1890FF;
        font-weight: bolder;
        font-size: 18px;
      }
    }
    .ivu-form-item {
      padding-right: 20px;
    }
  }
}
</style>
