<template>
  <div class="activity-form">
    <br>
    <h1>上传参赛作品</h1>
    <div class="flex input-row">
      <input v-model="signInfo.name" type="text" placeholder="请输入姓名">
      <input v-model="signInfo.projectName" type="text" placeholder="请输入项目名称">
    </div>
    <div class="flex input-row">
      <Select class="as-select" v-model="signInfo.projectType" placeholder="请选择项目">
        <Option class="as-item" value="信息技术+交通">信息技术+交通</Option>
        <Option class="as-item" value="智能制造+交通">智能制造+交通</Option>
        <Option class="as-item" value="新材料+交通">新材料+交通</Option>
        <Option class="as-item" value="人工智能+交通">人工智能+交通</Option>
        <Option class="as-item" value="新能源+交通">新能源+交通</Option>
      </Select>
      <input v-model="signInfo.unitName" type="text" placeholder="请输入所在机构">
    </div>
    <div class="flex input-row">
      <input v-model="signInfo.mobile" type="number" placeholder="请输入电话">
      <div class="input2">
        <Col span="16">
          <input v-model="signInfo.msgCode" type="number" placeholder="请输入验证码">
        </Col>
        <Col span="8" class="flex" style="height: 55px">
          <Button @click="getCode" :disabled="smsDisable" :loading="sendingCode" type="primary" class="code">{{smsDisable? smsTime:sendingCode? '':'获取验证码'}}</Button>
        </Col>
      </div>  
    </div>
    <div class="flex input-row">
      <div class="link">
        <Upload action="" :show-upload-list="false" :disabled="uploading" slot="append" :before-upload="onRead">
          <Button type="primary" class="upload" :loading="uploading" icon="md-cloud-upload">
            {{uploading? '上传中..':'上传附件'}}
          </Button>
          <span class="file-name">{{fileName}}</span>
        </Upload>
      </div>
      <div class="link">
        <Button type="primary" class="upload" @click="openNew()" icon="ios-link-outline">下载报名附件</Button>
      </div>
    </div>
    <div class="flex input-row">
      <textarea v-model="signInfo.memo" rows="3" placeholder="留言内容"></textarea>
    </div>
    <div class="btn tc" @click="submit()">提交</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      uploading: false,
      fileName: '',
      showDialog: false,
      submiting: false,
      sendingCode: false,
      smsDisable: false,
      smsTimer: null,
      smsTime: 60,
      signInfo: {
        activityId: this.$route.params.id,
        name: '',
        mobile: '',
        unitName: '',
        enclosure: '',
        memo: '',
        projectType: '', // 活动报名类型
        projectName: '', //活动报名项目名称
      },
    }
  },
  methods: {
    getCode() {
      if (!this.signInfo.mobile) {
        this.$Message.warning('请先输入手机号')
        return
      }
      if (!(/^((1[3-9][0-9])+\d{8})$/).test(this.signInfo.mobile)) {
        this.$Message.warning('请输入正确的手机号')
        return
      }
      this.sendingCode = true
      this.$http.get('/sys/sms/sendCheck',{
        params: {
          mobile: this.signInfo.mobile
        }
      }).then(res => {
        this.sendingCode = false
        if (res.code === 0) {
          this.signInfo.msgHash = res.data.msgHash
          this.signInfo.msgTime = res.data.msgTime
          this.$Message.success('短信已发送')
          this.smsDisable = true
          this.smsTimer = setInterval(() => {
            this.smsTime--
            if (this.smsTime === 0) {
              this.smsDisable = false
              clearInterval(this.smsTimer)
              this.smsTimer = null
              this.smsTime = 60
            }
          }, 1000);
        } else {
          this.$Message.warning(res.msg)
        }
      }).catch(err => {
        this.sendingCode = false
        this.$Message.warning(err.message)
      })
    },
    reSet() {
      this.fileName = ''
      this.signInfo = {
        activityId: this.$route.params.id,
        name: '',
        mobile: '',
        unitName: '',
        enclosure: '',
        memo: ''
      }
    },
    openNew() {
      window.open('//itie-static.oss-cn-hangzhou.aliyuncs.com/PC%E7%AB%AF%E5%AE%98%E7%BD%91/%E6%96%87%E6%A1%A3%E4%B8%8B%E8%BD%BD/%E5%8F%82%E8%B5%9B%E9%A1%B9%E7%9B%AE%E5%9F%BA%E6%9C%AC%E4%BF%A1%E6%81%AF%E8%A1%A8.docx','_blank')
    },
    onRead(e) {
      this.uploading = true
      this.$store.dispatch('uploadFile',{file: e, path: '/collection/'}).then(url=> {
        this.uploading = false
        this.fileName = e.name
        this.signInfo.enclosure = url
      }).catch(err => {
        this.uploading = false
      })
    },
    submit() {
      if (!this.signInfo.name) {
        this.submiting = false
        this.$Message.warning('请输入姓名')
        return
      }
      if (!this.signInfo.mobile) {
        this.submiting = false
        this.$Message.warning('请输入电话号码')
        return
      }
      if (!this.signInfo.projectName) {
        this.submiting = false
        this.$Message.warning('请输入项目名称')
        return
      }
      if (!this.signInfo.projectType) {
        this.submiting = false
        this.$Message.warning('请选择项目类型')
        return
      }
      if (!this.signInfo.unitName) {
        this.submiting = false
        this.$Message.warning('请输入单位名称')
        return
      }
      if (!this.signInfo.msgCode) {
        this.submiting = false
        this.$Message.warning('请输入验证码')
        return
      }
      if (!this.signInfo.enclosure) {
        this.$Message.warning('请上传附件')
        this.submiting = false
        return
      }
      this.submiting = true
      this.$http.post('/meeting/activitysign/',
        this.signInfo
      ).then(res => {
        this.submiting = false
        if (res.code === 0) {
          this.showDialog = false
          this.reSet()
          this.$Message.success('提交成功')
        } else {
          this.$Message.warning(res.msg)
        }
      }).catch(err => {
        this.submiting = false
        this.$Message.error(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.activity-form {
  margin: 20px auto;
  width: 1060px;
  height: 650px;
  border: 1px solid rgba(74, 145, 226, 0.5);
  border-radius: 3px;
  background: #EEF6FF;
  .btn {
    font-size: 16px;
    color: #FFF;
    width: 240px;
    height: 55px;
    background: #4A90E2;
    border-radius: 4px;
    line-height: 55px;
    margin: 0 auto;
    user-select: none;
    cursor: pointer;
    margin-top: 10px;
  }
  h1 {
    line-height: 80px;
    color: #0769CC;
    text-align: center;
  }
  .input-row {
    justify-content: space-around;
    padding: 10px 0;
    input, textarea {
      padding: 15px;
      font-size: 15px;
      color: #333;
      width: 450px;
      height: 55px;
      background: #FFF;
      border-radius: 4px;
    }
    .input2 {
      width: 450px;
      input {
        width: 300px;
      }
      .flex {
        height: 100%;
        justify-content: flex-end;
      }
    }
    textarea {
      height: auto;
      width: calc(100% - 80px);
    }
  }
  .link {
    width: 450px;
  }
  .row {
    justify-content: flex-start;
    .ivu-radio-group {
      padding-left: 25px;
    }
    .ivu-radio-wrapper {
      color: #333;
      font-size: 16px;
      margin-right: 25px;
    }
  }
  .ivu-upload {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .upload {
    background: #B4D9FF;
    color: #0769CC;
    border: 1px solid #0769CC;
  }
  .file-name {
    color: #333;
    margin-left: 10px;
  }
  .code {
    height: 49px;
  }
}
</style>
<style lang="less">
.as-select {
  height: 55px;
  font-size: 15px;
  color: #333;
  width: 450px;
  height: 55px;
  border-radius: 4px;
  background: #FFF;
  .ivu-select-selection {
    background: none;
    border: none;
    height: 45px;
    margin: 5px 0;
    padding: 0 10px;
    font-size: 16px;
    color: #333;
    line-height: 45px;
    .ivu-select-selected-value {
      font-size: 16px;
      color: #333;
      line-height: 45px;
    }
    .ivu-select-placeholder {
      font-size: 16px;
      color: #888;
      line-height: 45px;
    }
    .ivu-icon {
      color: #333;
    }
  }
  .ivu-select-input {
    font-size: 16px;
    color: #333;
    line-height: 45px;
  }
  .ivu-select-dropdown {
    background: #FFF;
    .ivu-select-item {
      font-size: 16px !important;
      line-height: 30px;
      color: #333 !important;
    }
    .ivu-select-item:hover, .ivu-select-item-focus {
      background: #DEDEDE;
      color: #333;
    }
  }
  .ivu-input:focus {
    box-shadow: none;
  }
}
</style>
