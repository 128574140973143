<template>
  <div class="activity-form">
    <div class="flex">
      <div class="btn tc" @click="show(false)">活动报名</div>
      <Upload action="1234" :show-upload-list="false" :disabled="uploading" slot="append" :before-upload="onRead">
        <div class="btn tc">
          {{uploading? '上传中..':'上传作品'}}
        </div>
        <span class="file-name">{{fileName}}</span>
      </Upload>
    </div>
    <Modal v-model="showDialog" class="activity-info-modal" title="报名信息">
      <div class="tip">
        <Icon class="iinfo" type="ios-alert-outline" />
        <b>帮助信息</b>
        <p>2019浙江国际智慧交通产业博览会•未来交通大会“吉祥物征集大赛”开始征集了，活动要求原创，不得抄袭，一经录用，请提供原稿，奖金丰厚，赶紧报名参赛吧！</p>
      </div>
      <Form :rules="ruleValidate" ref="signForm" :model="signInfo" :label-width="80">
        <FormItem label="姓名:" prop="name">
          <Input v-model="signInfo.name" placeholder="请输入姓名"></Input>
        </FormItem>
        <FormItem label="电话:" prop="mobile">
          <Input v-model="signInfo.mobile" placeholder="请输入电话"></Input>
        </FormItem>
        <FormItem label="验证码:" prop="msgCode">
          <Col span="16"><Input v-model="signInfo.msgCode" placeholder="请输入验证码"></Input></Col>
          <Col span="8"><Button class="fr" type="primary" :disabled="smsDisable" :loading="sendingCode" @click="getCode()">{{smsDisable? smsTime:sendingCode? '':'获取验证码'}}</Button></Col>
        </FormItem>
        <FormItem label="邮箱:">
          <Input v-model="signInfo.email" placeholder="请输入邮箱"></Input>
        </FormItem>
        <FormItem label="学校/单位:" prop="unitName">
          <Input v-model="signInfo.unitName" placeholder="请输入学校或单位名称"></Input>
        </FormItem>
      </Form>
      <div slot="footer"><Button type="primary" :loading="submiting" @click="submit()">确定</Button></div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showDialog: false,
      showUpload: false,
      uploading: false,
      submiting: false,
      sendingCode: false,
      smsDisable: false,
      smsTimer: null,
      smsTime: 60,
      fileName: '',
      ruleValidate: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        unitName: [{ required: true, message: '请输入学校/单位名称', trigger: 'blur' }],
        msgCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      signInfo: {
        activityId: this.$route.params.id,
        name: '',
        mobile: '',
        msgCode: '', // 验证码
      }
    }
  },
  methods: {
    show(flag) {
      this.showUpload = flag
      this.showDialog = true
    },
    getCode() {
      if (!this.signInfo.mobile) {
        this.$Message.warning('请先输入手机号')
        return
      }
      if (!(/^((1[3-9][0-9])+\d{8})$/).test(this.signInfo.mobile)) {
        this.$Message.warning('请输入正确的手机号')
        return
      }
      this.sendingCode = true
      this.$http.get('/sys/sms/sendCheck',{
        params: {
          mobile: this.signInfo.mobile
        }
      }).then(res => {
        this.sendingCode = false
        if (res.code === 0) {
          this.signInfo.msgHash = res.data.msgHash
          this.signInfo.msgTime = res.data.msgTime
          this.$Message.success('短信已发送')
          this.smsDisable = true
          this.smsTimer = setInterval(() => {
            this.smsTime--
            if (this.smsTime === 0) {
              this.smsDisable = false
              clearInterval(this.smsTimer)
              this.smsTimer = null
              this.smsTime = 60
            }
          }, 1000);
        } else {
          this.$Message.warning(res.msg)
        }
      }).catch(err => {
        this.sendingCode = false
        this.$Message.warning(err.message)
      })
    },
    onRead(e) {
      this.uploading = true
      this.$store.dispatch('uploadFile',{file: e, path: '/collection/'}).then(url=> {
        this.uploading = false
        this.signInfo.enclosure = url
        this.$Message.warning('请继续输入报名信息！')
        this.showDialog = true
      }).catch(err => {
        this.uploading = false
      })
    },
    reSet() {
      this.fileName = ''
      this.signInfo = {
        activityId: this.$route.params.id,
        name: '',
        mobile: '',
        unitName: '',
        enclosure: '',
        memo: ''
      }
    },
    submit() {
      this.$refs.signForm.validate((valid) => {
        if (valid) {
          this.submiting = true
          this.$http.post('/meeting/activitysign/',
            this.signInfo
          ).then(res => {
            this.submiting = false
            if (res.code === 0) {
              this.showDialog = false
              this.reSet()
              this.$Message.success('提交成功')
            } else {
              this.$Message.warning(res.msg)
            }
          }).catch(err => {
            this.submiting = false
            this.$Message.error(err)
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.flex {
  justify-content: space-around;
  border-top: 1px solid #2d8cf0;
  padding: 25px;
  padding-right: 100px;
  .btn {
    color: #FFF;
    font-weight: 600;
    width: 300px;
    height: 55px;
    font-size: 16px;
    border-radius: 4px;
    line-height: 55px;
    user-select: none;
    cursor: pointer;
    background: linear-gradient(to right, #4489FC , #2B5FFE);
  }
  .btn:last-child {
    background: linear-gradient(to right, #933EFF , #8F5EFE);
  }
}
</style>
